import React from 'react';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formSuccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			imageDesktop: '',
      selectedModelId: '',
      campaign: ''
		}
	}

	componentDidMount() {
        let event = "Thank you - form completed";
        let eventProperties = {
            "form": "testdrive",
            "country": this.props.match.params.country,
            "language": this.props.match.params.lang
        };

        amplitude.getInstance().logEvent(event, eventProperties);

		this.props.sidebarSetup(0, false);
		this.updateWindowDimensions();
        this.setState({
          selectedModelId: localStorage.getItem('model-dynamics'),
          editBtn: false,
          campaign: localStorage.getItem('campaign') ? 'testdrive-' + localStorage.getItem('campaign') : 'testdrive-form'
        })
		window.addEventListener("resize", this.updateWindowDimensions);
	}

	componentWillUnmount() {
		localStorage.clear();
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({width: window.innerWidth});

		if (window.innerWidth < 769) {
			this.setState({
				imageDesktop: false
			})
		} else {
			this.setState({
				imageDesktop: true
			})
		}
	};

	handleClick = () => {
        window.parent.postMessage('scroll', '*');

        let event = "Form closed";
        let eventProperties = {
            "form": "testdrive",
            "country": this.props.match.params.country,
            "language": this.props.match.params.lang
        };

        amplitude.getInstance().logEvent(event, eventProperties);

		if (!this.props.disableDealer) {
			window.top.location.href = this.props.settings.homepage
		} else {

			this.props.dealers.forEach((item) => {
				if (item.id === localStorage.getItem('dealer')) {
					window.top.location.href = item.homepage
				}
			})
		}
	}

	render() {

		let translate = this.props.translate;
		let settings = this.props.settings;

		return (
			<div className="form__success">
				<div className="form__sidebar"
						 style={{backgroundImage: `url(${this.state.imageDesktop ? settings.image_testdrive_desktop : settings.image_testdrive_mobile})`}}>
				</div>

				<div className="form__content">
					<div>
						<h2 className={'aui-headline-1 success-'+this.state.selectedModelId+' success-'+this.state.campaign}>{translate.success_title}</h2>
						<h4 className="aui-headline-4">{translate.success_subtitle}</h4>
						<p className="aui-caption">{translate.success_text}</p>
						<div className="buttons-wrap">
                <button className={'aui-button aui-button--secondary aui-js-response success-'+this.state.campaign}
											type="button"
											onClick={this.handleClick}>
								{translate.success_submit}
							</button>
						</div>
					</div>
				</div>
			</div>

		)
	}
}

export default formSuccess;
