import React from 'react';
import '../Form.scss';

class formSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stepTitles: [],
			width: 0,
			imageDesktop: '',
			receivedDesktopImage: '',
			receivedMobileImage: '',
            disableDealer: false,
            disableModel: false
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener("resize", this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth});

		if(window.innerWidth < 769){
			this.setState({
				imageDesktop: false
			})
		}else{
			this.setState({
				imageDesktop: true
			})
		}
	};

	componentWillReceiveProps(nextProps) {

		if(nextProps.settings.image_testdrive_desktop !== this.state.receivedDesktopImage){
			this.setState({
				receivedDesktopImage: nextProps.settings.image_testdrive_desktop
			})
		}

		if(nextProps.settings.image_testdrive_mobile !== this.state.receivedMobileImage){
			this.setState({
				receivedMobileImage: nextProps.settings.image_testdrive_mobile
			})
		}

		if (!nextProps.disableDealer){
            this.setState({
                stepTitles: [
                    nextProps.translate.test_drive_sidebar_user_info,
                    nextProps.translate.test_drive_sidebar_choose_car,
                    nextProps.translate.test_drive_sidebar_choose_dealer,
                    nextProps.translate.test_drive_sidebar_choose_time,
                    nextProps.translate.test_drive_sidebar_summary,
                ],
            })
		} else{
    		this.setState({
    			stepTitles: [
    				nextProps.translate.test_drive_sidebar_user_info,
    				nextProps.translate.test_drive_sidebar_choose_car,
    				nextProps.translate.test_drive_sidebar_choose_time,
    				nextProps.translate.test_drive_sidebar_summary,
    			],
    		})
		}
	}


	getClass(index){
		let stepClass = ''

		if(index <= this.props.step - 1){
			stepClass = 'done'
		}
		if(index === parseInt(this.props.step) ) {
			stepClass = 'active'
		}
			return stepClass
	}

	render() {
		return(
			<div className="form__sidebar"
					 style={{backgroundImage: `url(${this.state.imageDesktop ? this.state.receivedDesktopImage : this.state.receivedMobileImage })`}}>
				<div className="overlay"></div>

				<div className="steps">
					<h4 className="aui-headline-4">{this.props.translate.test_drive_sidebar_title}</h4>

					<ul>
						{
							this.state.stepTitles.map((item, index) =>
								<li className={this.getClass(index+1)} key={index}>{item}</li>
							)
						}
					</ul>
				</div>

			</div>
		)
	}
}

export default formSidebar;
