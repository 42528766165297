import React from 'react';
import {Textfield} from '@audi/audi-ui'
import axios from "axios";
import amplitude from 'amplitude-js';
import '../Form.scss';


class formUserInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: 'preorder',
			editLink: '?edit',
			editBtn: false,
			callback: '1',
			name: '',
			phone: '',
			email: '',

			nameError: false,
			phoneError: false,
			emailError: false,

			formValid: false,
			phoneFormValid: false,
			nameValid: false,
			emailValid: false,
			phoneValid: false,

			formStep: 1
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleCall = this.handleCall.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
        amplitude.getInstance().init("849d00a092bb01538ad10fb5ddcd75d5");

        let event = "Form opened";
        let eventProperties = {
            "form": "preorder",
            "country": this.props.match.params.country,
            "language": this.props.match.params.lang
        };

        amplitude.getInstance().logEvent(event, eventProperties);

		this.props.sidebarSetup(this.state.formStep, true);

		if (this.props.location.search === this.state.editLink) {

			this.setState({
				name: localStorage.getItem('name'),
				phone: localStorage.getItem('phone'),
				email: localStorage.getItem('email'),
				editBtn: false
			})

			this._getStorageData('name');
			this._getStorageData('phone');
			this._getStorageData('email');
		} else {
			Textfield.upgradeElements();
			this.setState({
				editBtn: true
			})
		}
	}

	_getStorageData = async (field) => {
		try {
			const value = await localStorage.getItem(field);
			if (value !== null) {
				this.validateField(field, value);
				Textfield.upgradeElements();
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
		this.validateField(name, event.target.value);
	};

	validateField(fieldName, value) {
		let nameValid = this.state.nameValid;
		let phoneValid = this.state.phoneValid;
		let emailValid = this.state.emailValid;

		switch (fieldName) {
			case 'name':
				nameValid = value.length >= 2;
				break;
			case 'phone':
				phoneValid = value.match(/^.\d{7,12}$/) ? true : false;
				;
				break;
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
			default:
				break;
		}

		this.setState({
			nameValid: nameValid,
			phoneValid: phoneValid,
			emailValid: emailValid,
		}, this.validateForm);

	}

	validateForm() {
		this.setState({
			formValid: this.state.nameValid && this.state.emailValid && this.state.phoneValid
		})
	}

	handleSubmit = event => {
		event.preventDefault();

		// Validate fields
		if (!this.state.formValid) {

			this.setState({
				nameError: !this.state.nameValid,
				phoneError: !this.state.phoneValid,
				emailError: !this.state.emailValid,
			})

		} else {
			window.parent.postMessage('scroll', '*');

			localStorage.setItem('form-name', this.state.form);
			localStorage.setItem('name', this.state.name);
			localStorage.setItem('phone', this.state.phone);
			localStorage.setItem('email', this.state.email);

			this.setState({
				nameError: false,
				phoneError: false,
				emailError: false
			});

            let event = "Step 1 - contacts completed";
            let eventProperties = {
                "form": "preorder",
                "country": this.props.match.params.country,
                "language": this.props.match.params.lang
            };

            amplitude.getInstance().logEvent(event, eventProperties);

            if (this.props.location.search === this.state.editLink) {
				this.props.history.push('preorder-summary')
			} else {
				if (!this.props.disableDealer) {
					this.props.history.push('preorder-choose-dealer');
				} else {
					this.props.history.push('preorder-summary');
				}
			}
		}
	}

	handleCall(e) {
		e.preventDefault();

		if (!this.state.phoneFormValid) {
			this.setState({
				phoneError: !this.state.phoneValid,
			})
		} else {

			this.setState({
				phoneError: false,
			})

			axios.post(window.API + '/' + this.props.match.params.lang + '/api/submit/' + this.props.match.params.country, {
					form: this.state.form,
					name: this.state.name,
					email: this.state.email,
					phone: this.state.phone,
					callback: this.state.callback
				})
				.then(function (response) {
					console.log(response);
				})
				.catch(function (error) {
					console.log(error);
				});

			this.props.history.push('preorder-success')
		}

	}

	render() {
		const translate = this.props.translate;

		return (
			<div className="form__content">
				<div>
					<h4 className="aui-headline-4">{translate.preorder_form_user_info_title}</h4>

					<form>
						<div className="aui-textfield aui-js-textfield aui-textfield--floating-label">
							<div className="aui-textfield__field">
								<input className="aui-textfield__input"
											 type="text"
											 name="name"
											 value={this.state.name}
											 onChange={this.handleChange('name')}/>
								<label className="aui-textfield__label" htmlFor="name">{translate.user_info_name}</label>
							</div>
							<span className={'aui-textfield__error ' + (this.state.nameError ? 'visible' : '')}>
										{translate.user_info_name_error}
									</span>
						</div>

						<div className="cols">

							<div className="aui-textfield aui-js-textfield aui-textfield--floating-label">
								<div className="aui-textfield__field">
									<input className="aui-textfield__input"
												 type="text"
												 name="phone"
												 value={this.state.phone}
												 onChange={this.handleChange('phone')}/>
									<label className="aui-textfield__label" htmlFor="phone">{translate.user_info_phone}</label>
								</div>
								<span className={'aui-textfield__error ' + (this.state.phoneError ? 'visible' : '')}>
											{translate.user_info_phone_error}
										</span>
							</div>

							<div className="col-helper">
								<p>{translate.common_string_or}</p>
							</div>

							<div className="aui-textfield aui-js-textfield aui-textfield--floating-label">
								<div className="aui-textfield__field">
									<input className="aui-textfield__input"
												 type="text"
												 name="email"
												 value={this.state.email}
												 onChange={this.handleChange('email')}/>
									<label className="aui-textfield__label" htmlFor="email">{translate.user_info_email}</label>
								</div>
								<span className={'aui-textfield__error ' + (this.state.emailError ? 'visible' : '')}>
											{translate.user_info_email_error}
										</span>
							</div>

						</div>

						<div className="buttons-wrap">
							<button className="aui-button aui-button--primary aui-js-response"
											type="submit"
											onClick={this.handleSubmit}>
								{this.state.editBtn ? translate.user_info_primary_btn : translate.common_element_save_btn}
							</button>
						</div>

                        <div className="aui-small" dangerouslySetInnerHTML={{ __html: translate.preorder_form_user_info_note }}></div>
					</form>
				</div>
			</div>
		)
	}
}

export default formUserInformation;
