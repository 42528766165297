import React from 'react';
import {Textfield} from '@audi/audi-ui';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formChooseDealer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editLink: '?edit',
			editBtn: false,
			maxLength: 500,
			inputLength: 500,
			inputValue: '',
			formStep: '4'
		}
	}

	componentDidMount() {
		if (!this.props.disableDealer) {
			this.props.sidebarSetup(this.state.formStep, true);
		} else {
			this.props.sidebarSetup(this.state.formStep - 1, true);
		}

		if (this.props.location.search === this.state.editLink) {
			this.setState({
				inputValue: localStorage.getItem('time'),
				editBtn: false
			})

			this._getStorageData('time');
		} else {
			Textfield.upgradeElements();
			this.setState({editBtn: true})
		}
	}

	_getStorageData = async (field) => {
		try {
			const value = await localStorage.getItem(field);
			if (value !== null) {
				Textfield.upgradeElements();
				this.validateField(field, value);
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	handleChange = e => {
		this.setState({
			inputValue: e.target.value,
			inputLength: this.state.maxLength - e.target.value.length
		})
	};

	handleSubmit = () => {
        window.parent.postMessage('scroll', '*');
		localStorage.setItem('time', this.state.inputValue);

        let event = "Step 4 - time chosen";
        let eventProperties = {
            "form": "service",
            "country": this.props.match.params.country,
            "language": this.props.match.params.lang
        };

        amplitude.getInstance().logEvent(event, eventProperties);

		this.props.history.push('service-summary')
	};

	addToTextarea = (e) => {
		this.setState({
			inputValue: this.state.inputValue + ' ' + e.currentTarget.textContent,
			inputLength: this.state.maxLength - this.state.inputValue.length
		})
	};

	render() {

		let translate = this.props.translate;

		return (

			<div className="form__content">
				<div>
					<h4 className="aui-headline-4">{translate.service_choose_time_title}</h4>

					<form>
						<div
							className={'aui-textfield aui-js-textfield aui-textfield--multiline ' + (this.state.inputValue.length > 0 ? 'is-focused is-dirty' : '')}
							data-autosize="">


							<p>{translate.service_choose_time_suggestions}</p>

							<ul className="time-select">
								<li onClick={this.addToTextarea}>{translate.service_choose_time_morning}</li>
								<li onClick={this.addToTextarea}>{translate.service_choose_time_noon}</li>
								<li onClick={this.addToTextarea}>{translate.service_choose_time_afternoon}</li>
								<li onClick={this.addToTextarea}>{translate.service_choose_time_evening}</li>
								<li onClick={this.addToTextarea}>{translate.service_choose_time_working_days}</li>
								<li onClick={this.addToTextarea}>{translate.service_choose_time_holidays}</li>
								<li onClick={this.addToTextarea}>{translate.service_choose_time_any_time}</li>
							</ul>

							<div className="aui-textfield__field">
										<textarea className="aui-textfield__input"
															id="question"
															name="name"
															value={this.state.inputValue}
															maxLength={this.state.maxLength}
															onChange={this.handleChange}
															rows="3">

										</textarea>

								<label className="aui-textfield__label"
											 htmlFor="question">{translate.service_choose_time_input_label}</label>
							</div>
							<span className="aui-textfield__description textarea-letter-count">
										<span>{translate.service_choose_time_input_caption}</span>
										<span>{this.state.inputLength}</span>
									</span>

						</div>

						<div className="buttons-wrap">
							<button className="aui-button aui-button--primary aui-js-response"
											onClick={this.handleSubmit}
											type="button">
								{this.state.editBtn ? translate.service_choose_time_submit : translate.common_element_save_btn}
							</button>
						</div>
					</form>
				</div>
			</div>

		)
	}
}

export default formChooseDealer;
