import React from 'react';
import {Checkbox} from "@audi/audi-ui/src";
import {NavLink} from "react-router-dom";
import axios from "axios"
import '../Form.scss';
import pen from "../../assets/img/edit.svg";

class formSummary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allowContact: false,
			allowInform: false,
			allowContactError: false,
			formStep: 5,
            disabled : false
		}
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		if (!this.props.disableDealer) {
			this.props.sidebarSetup(this.state.formStep, true);
		} else {
			this.props.sidebarSetup(this.state.formStep - 1, true);
		}

		Checkbox.upgradeElements()
	}

	handleChange = (e) => {
		if (e.target.name === 'allowContact') {
			this.setState({
				allowContact: !this.state.allowContact,
			})
		}

		if (e.target.name === 'allowInform') {
			this.setState({
				allowInform: !this.state.allowInform,
			})
		}
	}

	submitData = () => {
        if (this.state.disabled) {
            return;
        }

        this.setState({disabled: true});

		if (this.state.allowContact) {
			axios
				.post(window.API + '/' + this.props.match.params.lang + '/api/submit/' + this.props.match.params.country, {
					form: localStorage.getItem('form-name'),
					name: localStorage.getItem('name'),
					phone: localStorage.getItem('phone'),
					email: localStorage.getItem('email'),
					car_number: localStorage.getItem('car-number'),
					dealer: localStorage.getItem('dealer'),
					message: localStorage.getItem('time'),
					token: localStorage.getItem('token'),
					page: localStorage.getItem('page'),
          campaign: localStorage.getItem('campaign'),
					allow_contact: this.state.allowContact,
					allow_inform: this.state.allowInform,
					callback: 0
				})
				.then(function (response) {
					this.setState({disabled: false})
				})
				.catch(function (error) {
					console.log(error);
                    this.setState({disabled: false})
				});

            window.parent.postMessage('scroll', '*');
			this.props.history.push('service-success')
		} else {
            this.setState({allowContactError: true, disabled: false})
		}
	}

	render() {

		let translate = this.props.translate;
		let dealer;
		let email;
		let phone;
		let timeText;

		if (!this.props.disableDealer) {
			dealer = <p className="aui-shortread">
				{translate.summary_dealer + ': ' + localStorage.getItem('dealer-name')}
				<NavLink to={'service-choose-dealer?edit'}> <img src={pen} alt=""/> </NavLink>
			</p>
		}

		if (localStorage.getItem('phone')) {
			phone = <p className="aui-shortread">{translate.summary_phone + ': ' + localStorage.getItem('phone')}</p>
		}

		if (localStorage.getItem('email')) {
			email = <p className="aui-shortread">{translate.summary_email + ': ' + localStorage.getItem('email')}</p>
		}

		if (localStorage.getItem('time')) {
			timeText = localStorage.getItem('time')
		} else {
			timeText = this.props.translate.test_drive_summary_time_not_entered
		}

		return (

			<div className="form__content">
				<div>
					<h4 className="aui-headline-3">{translate.summary_title}</h4>

					<div className="summary__item">
						<p className="aui-shortread"><strong>{localStorage.getItem('name')}</strong></p>
						{phone}
						{email}
						<NavLink to={'service?edit'}> <img src={pen} alt=""/> </NavLink>
					</div>

					<div className="summary__item">
						<p className="aui-shortread">
							{translate.service_form_summary_car_number + ': ' + localStorage.getItem('car-number')}
							<NavLink to={'service-car-info?edit'}> <img src={pen} alt=""/> </NavLink>
						</p>
						{dealer}
						<p className="aui-shortread">
							{timeText}
							<NavLink to={'service-choose-time?edit'}> <img src={pen} alt=""/> </NavLink>
						</p>
					</div>

					<div className="checkbox__wrap">
						<label className={'aui-checkbox aui-js-checkbox ' + (this.state.allowContactError ? 'error' : '')}>
							<input className="aui-checkbox__input"
										 type="checkbox"
										 name="allowContact"
										 value={this.state.allowContact}
										 onChange={this.handleChange}/>
							<span  className="aui-checkbox__label aui-small"
										 dangerouslySetInnerHTML={{__html: translate.summary_allow_contact}}>
							</span>
						</label>

						<label className="aui-checkbox aui-js-checkbox">
							<input className="aui-checkbox__input"
										 type="checkbox"
										 name="allowInform"
										 value={this.state.allowInform}
										 onChange={this.handleChange}/>
							<span className="aui-checkbox__label aui-small">{translate.summary_allow_inform}</span>
						</label>
					</div>

					<div className="buttons-wrap">
						<button className="aui-button aui-button--primary aui-js-response"
										type="button"
                                        disabled={this.state.disabled}
										onClick={this.submitData}>
							{translate.summary_submit}
						</button>
					</div>
				</div>
			</div>

		)
	}
}

export default formSummary;
