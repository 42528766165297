import React from 'react';
import {Textfield} from '@audi/audi-ui';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formChooseDealer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editLink: '?edit',
			editBtn: false,
			maxLength: 500,
			inputLength: 500,
			inputValue: '',
			showError: false,
			formStep: 3
		}
	}

	componentDidMount() {
        amplitude.getInstance().init("849d00a092bb01538ad10fb5ddcd75d5");

		if(!this.props.disableDealer){
			this.props.sidebarSetup(this.state.formStep, true);
		} else{
			this.props.sidebarSetup(this.state.formStep - 1, true);
		}

		if (this.props.location.search === this.state.editLink) {
			this.setState({
				inputValue: localStorage.getItem('question'),
				editBtn: false,
			})

			this._getStorageData('question');
		} else {
			Textfield.upgradeElements();
			this.setState({
				editBtn: true,
			})
		}
	}

	_getStorageData = async (field) => {
		try {
			const value = await localStorage.getItem(field);
			if (value !== null) {
				Textfield.upgradeElements();
				this.validateField(field, value);
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	handleChange = e => {
		this.setState({
			inputValue: e.target.value,
			inputLength: this.state.maxLength - e.target.value.length
		})
	}

	handleSubmit = () => {
		if (this.state.inputValue.length !== 0) {
            window.parent.postMessage('scroll', '*');
			localStorage.setItem('question', this.state.inputValue);

            let event = "Step 3 - question added";
            let eventProperties = {
                "form": "contact",
                "country": this.props.match.params.country,
                "language": this.props.match.params.lang
            };

            amplitude.getInstance().logEvent(event, eventProperties);

			this.props.history.push('contact-summary');
		} else {
			this.setState({showError: true})
		}
	}

	render() {

		let translate = this.props.translate;

		return (


			<div className="form__content">
				<div>
					<h4 className="aui-headline-4">{translate.subject_title}</h4>

					<form>
						<div className="aui-textfield aui-js-textfield aui-textfield--multiline" data-autosize="">
							<div className="aui-textfield__field">
										<textarea className="aui-textfield__input"
															id="question"
															value={this.state.inputValue}
															maxLength={this.state.maxLength}
															onChange={this.handleChange}
															rows="3">

										</textarea>

								<label className="aui-textfield__label" htmlFor="question">{translate.subject_question}</label>
							</div>
							<span className="aui-textfield__description textarea-letter-count">
										<span>{translate.subject_letter_count}</span>
										<span>{this.state.inputLength}</span>
									</span>

							<span className={'aui-textfield__error ' + (this.state.showError ? 'visible' : '')}>
										{translate.subject_question_error}
										</span>
						</div>

						<div className="buttons-wrap">
							<button className="aui-button aui-button--primary aui-js-response"
											onClick={this.handleSubmit}
											type="button">
								{this.state.editBtn ? translate.subject_submit : translate.common_element_save_btn}
							</button>
						</div>
					</form>
				</div>
			</div>

		)
	}
}

export default formChooseDealer;
