import React from 'react';
import {Textfield} from '@audi/audi-ui';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formChooseDealer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editLink: '?edit',
			editBtn: false,
            selectedValue: 'not-selected',
            selectedName: '',
            showError: false,
			formStep: 3
		}
	}

	componentDidMount() {
		this.props.sidebarSetup(this.state.formStep, true);

		if (this.props.location.search === this.state.editLink) {
			this.setState({
				selectedValue: localStorage.getItem('time'),
				editBtn: false
			})

			this._getStorageData('time');
		} else {
			Textfield.upgradeElements();
			this.setState({editBtn: true})
		}
	}

	_getStorageData = async (field) => {
		try {
			const value = await localStorage.getItem(field);
			if (value !== null) {
				Textfield.upgradeElements();
				this.validateField(field, value);
			}
		} catch (error) {
			// Error retrieving data
		}
	};

    handleChange = (e) => {

		this.setState({
			selectedValue: e.target.value,
            selectedName: e.target.options[e.target.selectedIndex].text
		})

		if (e.target.value !== 'not-selected') {
			this.setState({showError: false})
		}
	}

	handleSubmit = () => {
        if (this.state.selectedValue !== 'not-selected') {
            window.parent.postMessage('scroll', '*');
			localStorage.setItem('time', this.state.selectedValue);
            localStorage.setItem('time-name', this.state.selectedName);

            let event = "Step 3 - time chosen";
            let eventProperties = {
                "form": "drivingacademy",
                "country": this.props.match.params.country,
                "language": this.props.match.params.lang
            };

            amplitude.getInstance().logEvent(event, eventProperties);

			this.props.history.push('driving-academy-summary')
		} else {
			this.setState({showError: true})
		}
	};

	render() {

		let translate = this.props.translate;
        let times = [{'id':0, 'title':translate.driving_academy_time_1}, {'id':1, 'title':translate.driving_academy_time_2}];

		return (
			<div className="form__content">
				<div>
					<h4 className="aui-headline-4">{translate.driving_academy_choose_time_title}</h4>

					<form>
                        <div className="aui-select aui-js-select aui-js-response aui-select--floating-label">
							<select value={this.state.selectedValue} className="aui-select__input" id="choose-event"
											onChange={this.handleChange}>

								<option className="aui-select__input-label"
												defaultValue="not-selected"
												value="not-selected">
									{translate.driving_academy_choose_time}
								</option>

								{times.map((event, index) =>
									<option key={index}
													value={event.id}>
										{event.title}
									</option>
								)}
							</select>
							<label className="aui-select__label"
										 htmlFor="choose-event">{translate.driving_academy_time_title}</label>
							<span className={'aui-textfield__error ' + (this.state.showError ? 'visible' : '')}>
										{translate.driving_academy_time_error}
										</span>
						</div>

						<div className="buttons-wrap">
							<button className="aui-button aui-button--primary aui-js-response"
											onClick={this.handleSubmit}>
								{this.state.editBtn ? translate.service_choose_time_submit : translate.common_element_save_btn}
							</button>
						</div>
					</form>
				</div>
			</div>
		)
	}
}

export default formChooseDealer;
