import React from 'react';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formChooseDealer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editLink: '?edit',
			editBtn: false,
			selectedValue: 'not-selected',
			selectedName: '',
			selectedModelImage: '',
            selectedModelId: '',
			showError: false,
			formStep: 2
		}
	}

	componentDidMount() {
		this.props.sidebarSetup(this.state.formStep, true);

		if (this.props.location.search === this.state.editLink || this.props.disableModel) {
			this.setState({
				selectedValue: localStorage.getItem('model'),
				editBtn: false
			})

			this.findImage(localStorage.getItem('model'))
            this.findDynamics(localStorage.getItem('model'))

		} else {
			this.setState({editBtn: true})
		}
	}

	handleChange = (e) => {

		this.setState({
			selectedValue: e.target.value,
			selectedName: e.target.options[e.target.selectedIndex].text
		})

		if (e.target.value !== 'not-selected') {
			this.setState({showError: false})
		}

		this.findImage(e.target.value)

    this.findDynamics(e.target.value)
	}

	findImage = (carID) => {

		this.props.models.forEach((item) => {

				if (item.id === carID) {
					this.setState({
						selectedModelImage: item.image
					})
				}
			}
		);
	}

    findDynamics = (carID) => {

		this.props.models.forEach((item) => {

				if (item.id === carID) {
					this.setState({
						selectedModelId: item.dynamics
					})
				}
			}
		);
	}

	handleSubmit = () => {
		if (this.state.selectedValue !== 'not-selected') {
            window.parent.postMessage('scroll', '*');
			localStorage.setItem('model', this.state.selectedValue);
			localStorage.setItem('model-name', this.state.selectedName);
			localStorage.setItem('model-image', this.state.selectedModelImage);
            localStorage.setItem('model-dynamics', this.state.selectedModelId);

            let event = "Step 2 - model selected";
            let eventProperties = {
                "form": "testdrive",
                "country": this.props.match.params.country,
                "language": this.props.match.params.lang
            };

            amplitude.getInstance().logEvent(event, eventProperties);

			if (this.props.location.search === this.state.editLink) {
				this.props.history.push('test-drive-summary')
			} else {
				if (!this.props.disableDealer) {
					this.props.history.push('test-drive-choose-dealer')
				} else{
					this.props.history.push('test-drive-choose-time');
				}
			}

		} else {
			this.setState({showError: true})
		}
	};

	render() {

		let translate = this.props.translate;
		let models = this.props.models;

		return (
			<div className="form__content">
				<div>
					<h4 className="aui-headline-4">{translate.test_drive_car_model_title}</h4>

					<form>

						<div className="model-image">
							<img src={this.state.selectedModelImage} alt="" className={'model-'+this.state.selectedModelId}/>
						</div>

						<div className="aui-select aui-js-select aui-js-response aui-select--floating-label">
							<select value={this.state.selectedValue} className="aui-select__input" id="choose-dealer"
											onChange={this.handleChange}>

								<option className="aui-select__input-label"
												defaultValue="not-selected"
												value="not-selected">
									{translate.test_drive_car_model_choose_model}
								</option>


								{models.map((dealer, index) =>
									<option key={index}
													value={dealer.id}>
										{dealer.title}
									</option>
								)}
							</select>
							<label className="aui-select__label"
										 htmlFor="choose-dealer">{translate.test_drive_car_model_title}</label>
							<span className={'aui-textfield__error ' + (this.state.showError ? 'visible' : '')}>
										{translate.test_drive_car_model_error}
										</span>
						</div>

						<div className="buttons-wrap">
							<button
								className="aui-button aui-button--primary aui-js-response"
								type="button"
								onClick={this.handleSubmit}>
								{this.state.editBtn ? translate.test_drive_car_model_submit : translate.common_element_save_btn}
							</button>
						</div>

					</form>
				</div>
			</div>

		)
	}
}

export default formChooseDealer;
