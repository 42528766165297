import React from 'react';
import {Textfield} from '@audi/audi-ui';
import amplitude from 'amplitude-js';
import '../Form.scss';


class formUserInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editLink: '?edit',
			editBtn: false,
			carNumber: '',
			showError: false,
			formStep: 2
		}

		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount() {
		this.props.sidebarSetup(this.state.formStep, true);

		if (this.props.location.search === this.state.editLink) {
			this.setState({
				carNumber: localStorage.getItem('car-number'),
				editBtn: false
			})
			this._getStorageData('car-number');

		} else {
			Textfield.upgradeElements();
			this.setState({editBtn: true})
		}
	}

	_getStorageData = async (field) => {
		try {
			const value = await localStorage.getItem(field);
			if (value !== null) {
				Textfield.upgradeElements();
				this.validateField(field, value);
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	handleChange = (e) => {
		e.preventDefault();
		this.setState({
			carNumber: e.target.value
		})
	}

	handleSubmit(e) {
		e.preventDefault();

		if (this.state.carNumber.length !== 0) {
            window.parent.postMessage('scroll', '*');
			localStorage.setItem('car-number', this.state.carNumber);

            let event = "Step 2 - car info added";
            let eventProperties = {
                "form": "service",
                "country": this.props.match.params.country,
                "language": this.props.match.params.lang
            };

            amplitude.getInstance().logEvent(event, eventProperties);

			if (this.props.location.search === this.state.editLink) {
				this.props.history.push('service-summary')
			} else {
				if (!this.props.disableDealer) {
					this.props.history.push('service-choose-dealer')
				} else{
					this.props.history.push('service-choose-time');
				}
			}

		} else {
			this.setState({showError: true})
		}
	}

	render() {
		const translate = this.props.translate;

		return (

			<div className="form__content">
				<div>
					<h4 className="aui-headline-4">{translate.service_car_info_title}</h4>

					<form>
						<div className="aui-textfield aui-js-textfield aui-textfield--floating-label">
							<div className="aui-textfield__field">
								<input className="aui-textfield__input"
											 type="text"
											 value={this.state.carNumber}
											 onChange={this.handleChange}/>
								<label className="aui-textfield__label" htmlFor="name">{translate.service_car_info_input}</label>
							</div>
							<span className={'aui-textfield__error ' + (this.state.showError ? 'visible' : '')}>
										{translate.service_car_info_input_error}
									</span>
						</div>

						<div className="buttons-wrap">
							<button className="aui-button aui-button--primary aui-js-response"
											type="submit"
											onClick={this.handleSubmit}>
								{this.state.editBtn ? translate.service_car_info_submit : translate.common_element_save_btn}
							</button>
						</div>
					</form>
				</div>
			</div>

		)
	}
}

export default formUserInformation;
