import React from 'react';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stepTitles: [],
			width: 0,
			imageDesktop: '',
			receivedDesktopImage: '',
			receivedMobileImage: ''
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener("resize", this.updateWindowDimensions);
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.settings.image_contacts_desktop !== this.state.receivedDesktopImage){
			this.setState({
				receivedDesktopImage: nextProps.settings.image_contacts_desktop
			})

		}

		if(nextProps.settings.image_contacts_mobile !== this.state.receivedMobileImage){
			this.setState({
				receivedMobileImage: nextProps.settings.image_contacts_mobile
			})
		}

		if(!nextProps.disableDealer){
			this.setState({
				stepTitles: [
					nextProps.translate.contact_sidebar_contact_info,
					nextProps.translate.contact_sidebar_choose_dealer,
					nextProps.translate.contact_sidebar_subject,
					nextProps.translate.contact_sidebar_summary,
				],
			})
		} else{
			this.setState({
				stepTitles: [
					nextProps.translate.contact_sidebar_contact_info,
					nextProps.translate.contact_sidebar_subject,
					nextProps.translate.contact_sidebar_summary,
				],
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth});

		if(window.innerWidth < 769){
			this.setState({
				imageDesktop: false
			})
		}else{
			this.setState({
				imageDesktop: true
			})
		}
	};

	getClass(index){
		let stepClass = ''

		if(index <= this.props.step - 1){
			stepClass = 'done'
		}
		if(index === parseInt(this.props.step) ) {
			stepClass = 'active'
		}
			return stepClass
	}

	navigateToComponent(element) {

		// 	if(element <= this.props.step - 1){
		// 		this.props.history.push(window.location.pathname);
		// 	}
		//
		// 	console.log(window.location.pathname)
		// }
	}
	render() {
		return(
			<div className="form__sidebar"
					 style={{backgroundImage: `url(${this.state.imageDesktop ? this.state.receivedDesktopImage : this.state.receivedMobileImage })`}}>

				<div className="overlay"></div>

				<div className="steps">
					<h4 className="aui-headline-4">{this.props.translate.contact_form_sidebar_title} </h4>

					<ul>
						{
							this.state.stepTitles.map((item, index) =>
								<li className={this.getClass(index+1)}
										// onClick={this.navigateToComponent.bind(this, index+1)}
										key={index}>{item}
								</li>
							)
						}
					</ul>
				</div>

			</div>
		)
	}
}

export default formSidebar;
